<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ invalid }"
    >
      <b-form>
        <div
          class="bg-white box"
          :class="{
            'p-1': $device.mobile,
            'form-add-product-link': !$device.mobile && !isModal,
          }"
        >
          <h4
            v-if="!isModal"
            class="mb-2 font-medium title-add-product-link"
          >
            {{ $t('myAdAccountsPage.modalAddProductLink.titleModal') }}
          </h4>

          <b-row
            v-if="queryType === 'warning'"
            class="mt-3 mb-2"
          >
            <b-col>
              <div class="warning-message d-flex align-items-center">
                <img
                  style="margin-right: 8px"
                  alt="alert"
                  :src="require('@/assets/images/common/ic-alert.svg')"
                >
                <p class="mb-0">
                  {{ $t('productLinkPage.textWarningAddProductLink') }}
                </p>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="input-form"
            >
              <b-form-group
                class="product-tag"
                label-for="tags-validation"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('myAdAccountsPage.textProductUrl') }} <span class="text-danger">* </span>
                    <img
                      v-b-tooltip.v-light="{
                        title:
                          `<div>- ${$t('myAdAccountsPage.firstContentTooltips')} <br/>- ${$t('myAdAccountsPage.secondContentTooltips')}</div>`,
                        html: true,
                      }"
                      :src="require('@/assets/images/common/ic-help.svg')"
                      alt="image"
                      style="margin-top: -10px"
                    >
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="fullName"
                  :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.nameInputProductLink')"
                  rules="required|url|productLink"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="productsData.productURL"
                      data-testid="input-product-link"
                      name="product link"
                      class="input-height input-product-link"
                      :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderProductLink')"
                      :tabindex="1"
                      :state="errors.length > 0 ? false : null"
                      :disabled="!!urlProductLink.url"
                      @focus="onFocus"
                      @blur="onBlur"
                    />
                  </b-input-group>
                  <div
                    class="notices-product-link"
                    :class="{
                      'input-invalid' : errors.length > 0 && !isFocus,
                      'input-focus' : isFocus
                    }"
                  >

                    <span class="title-notices"><span v-if="!isEmediaPlatform">🔥🔥🔥</span> {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textNotices') }}:</span>
                    <ul class="list-notices">
                      <li class="item-notices">
                        <span
                          class="content-notices"
                        >{{ $t('productLinkPage.firstNoticesAddProductLink') }}</span>
                      </li>
                      <li class="item-notices">
                        <span
                          class="content-notices"
                        >{{ $t('productLinkPage.firstNoticesInputProductLink') }}</span>
                      </li>
                      <li class="item-notices">
                        <span
                          class="content-notices"
                        >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentSecondNotices') }}
                          <a
                            href="https://www.google.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue"
                          >https://www.google.com</a>
                          {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIsDifferentFrom') }}
                          <a
                            href="https://google.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue"
                          >https://google.com</a>. {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textInCase') }}</span>
                      </li>
                      <li class="item-notices">
                        <span
                          class="content-notices"
                        >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentFinalNotices') }}</span>
                      </li>
                    </ul>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="target-market-form"
            >
              <validation-provider
                #default="{}"
                name="country"
                rules="required"
              >
                <b-form-group class="m-0 country-tag">
                  <div class="d-flex justify-content-between">
                    <label class="form-label">
                      {{ $t('myAdAccountsPage.labelTargetMarket') }} <span class="text-danger">*</span>
                      <span class="font-small-2 text-placeholder">
                        ({{ $t('myAdAccountsPage.contentSubTargetMarket') }})</span>
                    </label>
                  </div>
                  <v-select
                    v-model="productsData.targetMarkets"
                    data-testid="select-target-market"
                    class="input-height"
                    multiple
                    :placeholder="$t('myAdAccountsPage.targetMarketPlaceholder')"
                    :disabled="!!urlProductLink.url"
                    :options="availableOptions"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <feather-icon
                          icon="ChevronDownIcon"
                          size="20"
                        />
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="isExclusivePartner"
              cols="12"
              class="form-upload-video"
            >
              <label class="form-label">
                {{ $t('productLinkPage.textUploadVideo') }} <span class="text-danger">*</span>
              </label>

              <div ref="upload-product-video">
                <upload-file />

                <small
                  v-if="imageError"
                  class="text-danger"
                >
                  {{ $t('payment.wiseTransfer.errorRequiredAttachment') }}
                </small>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="listVideoSelected && listVideoSelected.length > 0"
            class="px-1"
          >
            <b-col
              cols="12"
              class="list-product-video-preview"
              :class="listVideoSelected && listVideoSelected.length > 5 ? 'height-400' : ''"
            >
              <div
                class="list-video-container"
              >
                <div
                  v-for="(itemVideo, index) in listVideoSelected"
                  :key="index"
                  class="list-product-video"
                >
                  <div
                    class="video-content"
                    @mouseover="handlerShowIconPlayVideo(itemVideo)"
                    @mouseleave="showIconPlay = false"
                  >
                    <video
                      class="video-list"
                      :src="itemVideo.videoURL"
                    />
                    <div
                      class="icon-play-video"
                      :class="videoHovered === itemVideo.videoURL && showIconPlay ? 'show-icon' : 'hide-icon'"
                      @click="showModalVideo(itemVideo.videoURL)"
                    >
                      <feather-icon
                        class="icon-play"
                        icon="PlayCircleIcon"
                        size="32"
                      />
                    </div>
                  </div>
                  <div class="name-video">
                    <p>
                      {{ getNameVideo(itemVideo.videoURL) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="text-right">
            <b-col cols="12">
              <p
                class="font-14 text-notes"
              >
                *{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentNotesStep',{platform: `${ platformName }`}) }}
              </p>
            </b-col>

            <b-col>
              <div class="btn-control">
                <btn-loading
                  variant-convert="btn-submit"
                  data-testid="btn-submit-product-links"
                  pill
                  :loading="loadingProductLink || loadingFile"
                  :disabled="invalid || validateProductLinkForm"
                  @click="submit"
                >
                  {{ $t('forgotPW.btnSendLinkReset') }}
                </btn-loading>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>

    <video-modal
      ref="modal-play-video"
      :video-preview="urlVideo || null"
    />
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  VBTooltip,
  // BImg,
} from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { required, url, productLink } from '@validations'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
// import { ref } from '@vue/composition-api'
import VideoModal from '@/views/product-links/components/VideoModal.vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import store from '@/store'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import UploadFile from './UploadFile.vue'

const { mapActions } = createNamespacedHelpers('auth')
const { mapActions: mapActionsFile } = createNamespacedHelpers('file')

const {
  mapActions: mapActionsProductLinks,
} = createNamespacedHelpers('productLinks')

export default {
  components: {
    VideoModal,
    vSelect,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,

    BInputGroup,
    // components
    BtnLoading,
    // vSelect,

    // validations
    ValidationProvider,
    ValidationObserver,
    // BImg,
    UploadFile,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [toastification, envMixin, stepGuideMixin, generalConfigsMixin],

  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

      // validation
      required,
      url,
      productLink,

      isFocus: false,
      imageError: false,
      urlProductLink: {},
      urlVideo: null,
      listVideoSelected: [],
      showIconPlay: false,
      videoHovered: '',
      nameVideoUploaded: '',
      errorVideoProduct: '',
      percent: 0,
      dragover: false,
    }
  },

  // setup() {
  //   const refVideo = ref(null)

  //   const { inputImageRenderer } = useInputImageRenderer(refVideo, () => {})

  //   return {
  //     refVideo,
  //     inputImageRenderer,
  //   }
  // },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      statusUser: 'auth/status',
      loadingUser: 'auth/loading',
      messageUser: 'auth/message',
      statusProductLink: 'productLinks/status',
      loadingProductLink: 'productLinks/loading',
      messageProductLink: 'productLinks/message',
      fileVideo: 'file/fileVideo',
      statusFile: 'file/status',
      messageFile: 'file/message',
      loadingFile: 'file/loading',
    }),

    validateProductLinkForm() {
      return this.isExclusivePartner && !this.fileVideo.fileUrl
    },

    getNameVideo() {
      return videoUrl => {
        const urlFile = 'https://files.ecomdy.com/videos/'
        return videoUrl?.split(urlFile)[1]
      }
    },

    availableOptions() {
      return this.listTargetMarket?.filter(
        opt => this.productsData?.targetMarkets?.indexOf(opt) === -1,
      )
    },

    queryType() {
      return this.$route?.query?.type
    },
  },

  watch: {
    $route: {
      handler(router) {
        if (router) {
          this.urlProductLink = router?.query
          if (this.listProductLink.length > 0 && this.urlProductLink.url) {
            const currentProductLink = this.listProductLink?.find(itemProductLink => itemProductLink.productUrl === this.urlProductLink?.url)
            this.listVideoSelected = currentProductLink?.summaryVideos?.videos || []
            this.productsData.productURL = currentProductLink.productUrl
            this.productsData.targetMarkets = currentProductLink.targetMarkets

            this.$nextTick(() => {
              this.scrollToUploadForm()
            })
          }
        }
      },
      deep: true,
      immediate: true,
    },

    user: {
      handler(userInfo) {
        if (userInfo?.data?.products && this.urlProductLink.url) {
          const productUrl = this.listProductLink?.find(item => item.productUrl === this.urlProductLink.url)
          if (productUrl) {
            this.productsData.productURL = productUrl.productUrl
            this.productsData.targetMarkets = productUrl.targetMarkets
            this.listVideoSelected = productUrl.summaryVideos?.videos
          }

          this.$nextTick(() => {
            this.scrollToUploadForm()
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['updateAccount', 'getAccountInfo']),
    ...mapActionsProductLinks(['createProductLink', 'createProductVideo']),
    ...mapActionsFile(['uploadVideo']),

    scrollToUploadForm() {
      const optionScroll = {
        behavior: 'auto',
        block: 'start',
        inline: 'nearest',
      }
      this.$refs['upload-product-video'].scrollIntoView(optionScroll)
    },

    handlerShowIconPlayVideo(videoInfo) {
      this.showIconPlay = true
      this.videoHovered = videoInfo.videoURL
    },

    showModalVideo(urlVideo) {
      this.urlVideo = urlVideo || this.fileVideo.fileUrl
      this.$refs['modal-play-video'].showModal()
    },

    // async upload(file) {
    //   if (file) {
    //     const refFile = this.$refs.refVideo.files[0]

    //     const config = {
    //       onUploadProgress: ({ loaded, total }) => {
    //         this.percent = Math.round((loaded / total) * 100)
    //       },
    //     }

    //     if (refFile.size > (1024 * 1024 * 100)) {
    //       this.toastFailure(this.$t('productLinkPage.toastVideoSizeLess'))
    //       this.videoFile = null
    //     } else {
    //       await this.uploadVideo({ file, config })
    //       if (this.statusFile) {
    //         this.nameVideoUploaded = this.videoFile?.name
    //         this.toastSuccess(this.$t('payment.toastUploadSuccess'))
    //       } else {
    //         this.toastFailure(this.messageFile)
    //       }
    //       this.percent = 0
    //     }
    //   }
    // },

    onFocus() {
      this.isFocus = true
    },

    onBlur() {
      this.isFocus = false
    },

    async requestCreateProductVideo() {
      const params = {
        action: 'add',
        videoURL: this.fileVideo?.fileUrl,
        productURL: this.productsData.productURL,
      }
      await this.createProductVideo(params)
      if (this.statusProductLink) {
        this.getAccountInfo()
        store.state.file.fileVideo = {}
        this.$router.push({ name: 'product-link' })
        this.toastSuccess(this.urlProductLink?.url ? this.$t('productLinkPage.toastUploadProductVideoSuccess') : this.$t('productLinkPage.toastCreateProductLinkSuccess'))
      } else {
        this.toastFailure(this.messageProductLink)
      }
    },

    async submit() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        if (this.urlProductLink?.url) {
          if (this.fileVideo.fileUrl) {
            this.requestCreateProductVideo()
          } else {
            this.scrollToUploadForm()
            this.errorVideoProduct = this.$t('productLinkPage.errorRequiredProductVideo')
          }
        } else {
          const params = this.productsData

          await this.createProductLink(params)
          if (this.statusProductLink) {
            this.getAccountInfo()

            if (this.isExclusivePartner) {
              this.requestCreateProductVideo()
            } else {
              this.toastSuccess(this.$t('productLinkPage.toastCreateProductLinkSuccess'))
              this.$router.push({ name: 'product-link' })
            }

            if (this.isModal) {
              this.$emit('addProductLinkSuccess', this.productsData)
            } else if (this.queryType === 'overview') {
              this.$router.push('/')
            }
            // else {
            //   this.$router.push({ name: 'product-link' })
            // }
          } else {
            this.toastFailure(this.messageProductLink)
          }
        }
      }
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.currency {
  .vs__dropdown-toggle {
    height: 100%;
    border-radius: 12px;
  }
}

.product-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .card {
    border-radius: 0.625rem;
  }

  .card-body {
    padding: 0.86rem 1rem;
  }
}

.country-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .vs__selected {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 5.09px 0 6.5px 0;
  }
}

.progress-upload-video{
  &.progress-bar-success {
    background-color: rgba(110,68,255,.12);

    .bg-success{
      background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
    }
  }
}
</style>

<style lang="scss" scoped>
.target-market-form {
  margin-bottom: 20px;
}

.product-tag {
  margin-bottom: 0;
}

.text-notes {
  color: rgba(0, 0, 0, 0.7);
  margin: 20px 0 0;
  text-align: start;
}

.form-upload-video {
  margin-bottom: 20px;

  .title-form-upload-video {
    font-size: 14px;
    margin-bottom: 12px;
  }
}

.item-video-uploaded {
  width: 100%;
  padding: 12px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  margin-top: 12px;

  .btn-close-video {
    position: absolute;
    right: -10px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .content-video-uploaded {
    display: flex;

    .ic-file-upload {
      margin-right: 10px;

      .ic-file {
        width: 54px;
        height: 54px;
      }
    }

    .content-file-upload {
      width: 100%;

      .name-video {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;

        .name-video-content {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.form-add-product-link {
  padding: 32px;
}

.title-add-product-link {
  margin-bottom: 32px;
}

.btn-control {
  margin-top: 24px;
}

.input-form {
  margin-bottom: 20px;
}

.box {
  border-radius: var(--border-radius-lg);
}

// .input-height {
//   height: 48px;
// }

.list-reason {
  li {
    margin-bottom: 5px;
  }
}

.alert-img {
  width: 18px;
}

.warning-message {
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px;
}

.input-product-link {
  border-radius: 12px 12px 0px 0px;
}

.notices-product-link {
  border: 1px solid #E8E9EC;
  border-radius: 0px 0px 12px 12px;
  border-top: none;

  padding: 14px;

  .title-notices {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }

  .list-notices {
    margin-top: 10px;

    .item-notices {
      margin-bottom: 8px;

      .content-notices {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.input-invalid {
    border-color: #e11c4e;
  }

  &.input-focus {
    border-color: #16213e;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
}

.upload {
  background: #f5f5f7;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  padding: 32px 0px;
  justify-content: center;
  margin: 0;
  font-size: 24px;
}

.drop-zoon--over {
  border: 1px dashed rgba(0, 0, 0, 0.8);

  p, span {
    opacity: .7;
  }
}

.sub-title {
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
}

.btn-upload-file {
  display: flex;
  padding: 12px 16px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: 100px;
  background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
}

.list-product-video-preview {
  padding: 0;
}

.height-400 {
  height: 400px;
  overflow-x: scroll;
}

.list-video-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.list-product-video {
  padding: .5rem;
}

.name-video {
  width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-content {
  position: relative;
  width: 190px;
  height: 110px;

  .video-list {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .icon-play-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon-play {
      z-index: 999;
      color: #fff;
    }

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }

    @keyframes showicon {
      0% {
        visibility: hidden;
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.text-error {
  color: #e11c4e;
}

.progress-upload-video{
  margin-top: 1rem;
}
</style>
