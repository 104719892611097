<template>
  <div>

    <b-button
      v-if="queryType !== 'overview'"
      variant="flat-secondary"
      class="mb-1"
      @click.prevent="backToProductLinks"
    >
      <feather-icon
        size="16"
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      {{ $t('productLinkPage.textBackToProductLinks') }}
    </b-button>
    <add-product-link-form />
  </div>
</template>

<script>
/* eslint-disable global-require */

import {
  BButton,
} from 'bootstrap-vue'
import store from '@/store'

import AddProductLinkForm from './AddProductLinkForm.vue'

export default {
  components: {
    AddProductLinkForm,
    BButton,
  },

  computed: {
    queryType() {
      return this.$route?.query?.type
    },
  },

  watch: {
    $route: {
      handler(route) {
        if (route?.name === 'add-product-link') {
          store.state.file.fileVideo = {}
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    backToProductLinks() {
      store.state.file.fileVideo = {}
      this.$router.push({ name: 'product-link' })
    },
  },
}
</script>
