<template>
  <b-modal
    ref="modal-show-video"
    id="modal-show-video"
    class="modal-show-video"
    size="xl"
    hide-footer
    hide-header
    centered
  >
    <!-- heeder modal -->
    <div
      class="icon-close cursor-pointer"
      @click="hideModal"
    >
      <feather-icon
        icon="XIcon"
        size="16"
      />
    </div>
    <!-- end heeder modal -->

    <div class="video-container">
      <video
        controls="video"
        autoplay
        :src="videoPreview"
        class="video-content"
      />
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },

  props: {
    videoPreview: {
      type: String,
      default: null,
    },
  },

  methods: {
    hideModal() {
      this.$refs['modal-show-video'].hide()
    },

    showModal() {
      this.$refs['modal-show-video'].show()
    },
  },
}
</script>

<style lang="scss">
#modal-show-video {
  max-height: 100vh;

  .modal-dialog {
    justify-content: center;
    @media(min-width: 1200px) {
      margin-right: 10%;
      margin-left: 10%;
    }
  }
  .modal-content {
    width: 720px;
    border-radius: 0;
    background-color:#fff;
  }

  .modal-body {
    padding: 0px !important;
  }
}
</style>

<style lang="scss" scoped>
.btn-hide{
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 9999;
}
.img-screenshot {
  max-height: calc(100vh - 3.5rem);
  max-width: 100%;
  border-radius: 8px;
}

.icon-close {
  color: white;
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 100px;
  background-color: #4d4d6c;
  line-height: 0;
  padding: 4px;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-content {
  width: 100%;
  height: 407px;
  max-height: 407px;
  box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
}
</style>
