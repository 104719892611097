<template>
  <div class="upload-file-container">
    <!-- Drop Zoon -->
    <div
      v-if="!loadingFile"
      id="dropZoon"
      class="upload-area__drop-zoon drop-zoon"
      :class="dragover ? 'drop-zoon--over' : null"
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
    >
      <div
        v-if="fileVideo.thumbUrl"
        class="drop-zoon__preview"
        @mouseover="showIconPlay = true"
        @mouseleave="showIconPlay = false"
      >
        <img
          id="previewImage"
          :src="fileVideo.thumbUrl"
          alt="Preview Image"
          class="drop-zoon__preview-image"
        >

        <div
          class="drop-zoon__remove-image"
          :class="showIconPlay ? 'show-icon' : 'hide-icon'"
          @click="handleRemoveImage"
        >
          <feather-icon
            class="icon-play"
            icon="XCircleIcon"
            size="20"
          />
        </div>

        <div
          class="icon-play-video"
          :class="showIconPlay ? 'show-icon' : 'hide-icon'"
          @click="showModalVideo(fileVideo)"
        >
          <feather-icon
            class="icon-play"
            icon="PlayCircleIcon"
            size="32"
          />
        </div>
      </div>
      <div
        v-else
        class="drop-zoon__content"
      >
        <h2 class="title-input-upload-file">
          {{ dragover ? $t('productLinkPage.textDropFileHere') : $t('productLinkPage.textYouCanUploadVideo') }}
        </h2>
        <p class="drop-zoon__paragraph">
          {{ $t('productLinkPage.textClickOnTheBtn') }}
        </p>
      </div>
      <btn-loading
        variant-convert="btn-submit"
        class="d-flex align-items-center"
        @click="handleUploadVideo"
      >
        <feather-icon
          :icon="fileVideo.thumbUrl ? 'RefreshCwIcon' : 'PlusIcon'"
          size="14"
          class="icon-upload"
        /> {{ fileVideo.thumbUrl ? $t('productLinkPage.textChangeVideo') : $t('productLinkPage.textUploadVideo') }}
      </btn-loading>
      <input
        ref="refVideo"
        id="fileInput"
        type="file"
        class="drop-zoon__file-input"
        accept=".mov, .mp4, .webm, .mpeg"
        @change="onFileChange"
      >
    </div>
    <!-- End Drop Zoon -->

    <!-- Progress bar upload -->
    <div
      v-if="percent"
      class="progress-upload-bar"
    >
      <div>
        <b-img
          :src="require('@/assets/images/icons/ic-file-upload.png')"
          style="width: 54px; margin-right: 10px;"
        />
      </div>
      <div class="w-100">
        <p
          v-if="(fileVideo && fileVideo.fileUrl) || (videoFile && videoFile.name)"
          class="progress-name-video"
        >
          File: <span class="name-video">{{ nameVideoUploaded }}</span>
        </p>

        <b-progress
          v-show="percent"
          :value="percent"
          max="100"
          variant="success"
          class="progress-bar-success progress-upload-video"
        />
      </div>
    </div>
    <!-- End progress bar upload -->

    <!-- Error upload product video -->
    <div class="error-upload-video">
      <p
        v-if="errorVideoProduct && !loadingFile"
        class="text-error"
      >
        {{ errorVideoProduct }}
      </p>
    </div>
    <!-- End error upload product video -->

    <video-modal
      ref="open-video-preview-modal"
      :video-preview="videoPreview"
    />
  </div>
</template>

<script>
import { BProgress, BImg } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import store from '@/store'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import VideoModal from './VideoModal.vue'

const { mapActions: mapActionsFile } = createNamespacedHelpers('file')
export default {

  components: {
    BtnLoading,
    BProgress,
    BImg,
    VideoModal,
  },

  mixins: [toastification],

  data() {
    return {
      dragover: false,
      videoFile: null,
      nameVideoUploaded: '',
      errorVideoProduct: '',
      percent: 0,
      showIconPlay: false,
      videoPreview: '',
    }
  },

  setup() {
    const refVideo = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refVideo, () => {})

    return {
      refVideo,
      inputImageRenderer,
    }
  },

  computed: {
    ...mapGetters({
      fileVideo: 'file/fileVideo',
      statusFile: 'file/status',
      messageFile: 'file/message',
      loadingFile: 'file/loading',
    }),
  },

  watch: {
    videoFile(val) {
      if (val) {
        this.upload(val)
      }
    },
  },

  methods: {
    ...mapActionsFile(['uploadVideo']),

    handleRemoveImage() {
      store.state.file.fileVideo = {}
      this.showIconPlay = false
    },

    showModalVideo(file) {
      this.videoPreview = file?.fileUrl
      this.$refs['open-video-preview-modal'].showModal()
    },

    handleUploadVideo() {
      if (!this.loadingFile) {
        this.$refs.refVideo.click()
      }
    },

    onFileChange(evt) {
      this.videoFile = evt.dataTransfer?.files[0] || this.$refs.refVideo?.files[0]
    },

    onDrop(evt) {
      this.$refs.refVideo.files = evt.dataTransfer.files
      this.dragover = false
      this.onFileChange(evt)
    },

    async upload(file) {
      if (file) {
        const refFile = this.$refs.refVideo.files[0]
        this.nameVideoUploaded = this.videoFile?.name

        const config = {
          onUploadProgress: ({ loaded, total }) => {
            this.percent = Math.round((loaded / total) * 100)
          },
        }

        if (refFile.size > (1024 * 1024 * 100)) {
          this.toastFailure(this.$t('productLinkPage.toastVideoSizeLess'))
        } else {
          await this.uploadVideo({ file, config })
          if (!this.statusFile) {
            this.toastFailure(this.messageFile)
          }
          this.percent = 0
        }
      }
    },
  },
}
</script>

<style lang="scss">
.progress-upload-bar{

  .progress-upload-video{
    height: 10px;
    &.progress-bar-success {
      margin-top: 10px;
      background-color: rgba(110,68,255,.12);

      .bg-success{
        background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
      }
    }
  }
}
</style>

<style lang='scss' scoped>

/* Drop Zoon */
.upload-area__drop-zoon {
  padding: 32px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f5f7;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: border-color 300ms ease-in-out;
}

.drop-zoon__content {
  text-align: center;
}

.title-input-upload-file {
  margin: 12px 0;
  font-size: 24px;
  font-weight: 400;
  transition: opacity 300ms ease-in-out;
}

.drop-zoon__paragraph {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.50);
  transition: opacity 300ms ease-in-out;
}

.btn-upload-file {
  display: flex;
  padding: 12px 16px;
  margin: 0;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: 100px;
  background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
}

.drop-zoon__preview {
  position: relative;
  margin-bottom: 20px;

  .drop-zoon__preview-image {
    width: 200px;
    height: 110px;
    object-fit: contain;
    border-radius: 6px;
  }

  .drop-zoon__remove-image {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #4d4d6c;
    border-radius: 50%;
    z-index: 9999;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }
  }

  .icon-play-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;

    .icon-play {
      z-index: 999;
      color: #fff;
    }

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }

    @keyframes showicon {
      0% {
        visibility: hidden;
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.drop-zoon__file-input {
  display: none;
}

/* (drop-zoon--over) Modifier Class */
.drop-zoon--over {
  border-color: rgba(0, 0, 0, 0.7);
}

.drop-zoon--over .title-input-upload-file,
.drop-zoon--over .drop-zoon__paragraph {
  opacity: 0.7;
}

/* (drop-zoon--over) Modifier Class */
// .drop-zoon--Uploaded {

// }

.drop-zoon--Uploaded .drop-zoon__paragraph {
  display: none;
}

/* File Details Area */
.upload-area__file-details {
  height: 0;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  transition: none 500ms ease-in-out;
  transition-property: opacity, visibility;
  transition-delay: 500ms;
}

/* (duploaded-file--open) Modifier Class */
.file-details--open {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.file-details__title {
  font-size: 1.125rem;
  font-weight: 500;
  color: rgb(196, 195, 196);
}

.progress-upload-bar {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
}

.progress-name-video {
  margin: 0;
  color: #16213e;
  font-size: 24px;
  width: 650px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-upload {
  margin-right: 6px;
}
</style>
